import heic2any from 'heic2any';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Image, ListGroup, Row } from 'react-bootstrap';
import { trackPromise } from 'react-promise-tracker';
import { toast } from 'react-toastify';
import Loader from '../../assets/images/loader.svg';
import Toaster from '../../components/Toaster';
import {
  getReimbursementsByPeriods,
  imagePresignedurl,
  updateReimbursementById
} from '../../services/reimbursement-service';
import { generateFileNameWithPath, getAllBenefit, validateFuelReceipts } from '../../utils/ReimbursementManagement';
import ReimbursementReasons from '../../constants/reimbursement_reasons';
import { minutesToDecimalHour } from '../../utils/TimesheetManagement';

const getFileExtension = (url) => {
  const pathParts = url.split('?')[0].split('/');
  const fileName = pathParts[pathParts.length - 1];
  const fileParts = fileName.split('.');
  return fileParts[fileParts.length - 1].toLowerCase();
};

function ReceiptList({ receipts, reimbursement }) {
  const [activeReceipt, setActiveReceipt] = useState(receipts[0]);
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef(null);
  const isFtc = process.env.REACT_APP_PROJECT_NAME;

  useEffect(() => {
    setFiles([]);
  }, []);

  const handleKeyPress = (event, callback) => {
    if (event.key === 'Enter') {
      callback();
    }
  };

  const removeFile = (indexToRemove) => {
    setFiles((prevFiles) =>
      prevFiles.filter((_, index) => index !== indexToRemove)
    );
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const triggerFileUpload = () => {
    fileInputRef.current.click();
  };

  const checkBenefitHours = async (employee, period) => {
    const serviceCityIds = employee?.service_city.map((city) => city._id);

    const rbody = {
      employee: employee?._id,
      service_city: serviceCityIds, // Pass the array of service_city _ids
      startDate: period?.start_date,
      endDate: period?.end_date,
    };
    const totalBenefitMinutes = await getAllBenefit(rbody);
    const totalBenefitHours = minutesToDecimalHour(totalBenefitMinutes);

    return totalBenefitHours;
  };

  async function getUserReimbursementsByPeriods(
    employeeId,
    reasonID,
    startDate,
    endDate
  ) {
    try {
      const response = await trackPromise(
        getReimbursementsByPeriods(employeeId, reasonID, startDate, endDate)
      );
      const result = response?.data.data.no_Of_Reimbursements;
      return result;
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    }
  }

  const onFilesChange = async (event) => {
    const uploadedFiles = Array.from(event.target.files);

    const employeeID = reimbursement.employee?._id;
    const reasonID = reimbursement.reason?._id;
    const startDate = reimbursement.period?.start_date;
    const endDate = reimbursement.period?.end_date;

    const totalFiles = await getUserReimbursementsByPeriods(
      employeeID,
      reasonID,
      startDate,
      endDate
    );

    if (
      reimbursement.reason._id === ReimbursementReasons[2]._id &&
      isFtc &&
      reimbursement.employee?.role.rolename === 'Chef'
    ) {
      try {
        const benefitHours = await checkBenefitHours(reimbursement.employee, reimbursement.period);
        if (validateFuelReceipts(
          benefitHours,
          totalFiles,
          files.length,
          uploadedFiles.length
        )) {
          setFiles((prevFiles) => [...prevFiles, ...uploadedFiles]);
        }
      } catch (error) {
        console.error('Error checking benefit hours:', error);
      }
    } else {
      setFiles((prevFiles) => [...prevFiles, ...uploadedFiles]);
    }
  };

  const uploadFile = async (file) => {
    if (!file) return null;

    try {
      let uploadBlob = file;
      const isHeic = file.type === 'image/heic';
      const uniqueFileNameWithPath = generateFileNameWithPath(file.name, isHeic);

      if (isHeic) {
        const blob = await heic2any({ blob: file, toType: 'image/jpeg' });
        uploadBlob = new Blob([blob], { type: 'image/jpeg' });
      }

      const response = await trackPromise(
        imagePresignedurl(
          JSON.stringify({
            fileName: uniqueFileNameWithPath?.split('/').pop(),
            fileType: uploadBlob.type,
          })
        )
      );

      if (response.status === 201) {
        toast.dark(<Toaster icon="notify" message={response.data.message} />);
      }

      const { presignedUrl } = response.data;

      await fetch(presignedUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': uploadBlob.type,
        },
        body: uploadBlob,
      });

      return uniqueFileNameWithPath;
    } catch (error) {
      console.error('Error uploading file:', file.name, error);
      return null;
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    if (files.length === 0) {
      toast.dark(
        <Toaster icon="error" message="Please upload at least one receipt." />
      );
      setIsLoading(false);
      return;
    }

    try {
      const fileNames = await Promise.all(
        files.map(async (file) => {
          try {
            return await uploadFile(file);
          } catch (err) {
            console.error(`Error uploading file ${file.name}:`, err);
            throw err;
          }
        })
      );

      const updatedReimbursement = { ...reimbursement, fileNames };

      const response = await trackPromise(
        updateReimbursementById(
          updatedReimbursement.reimbursementId,
          updatedReimbursement
        )
      );

      if (response.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      toast.dark(
        <Toaster icon="error" message={error?.response?.data?.message ?? 'Something went wrong Catch!!!'} />
      );
    } finally {
      setIsLoading(false);
    }
  };

  const renderReceiptsSection = () => (
    <div>
      <ReceiptsList
        receipts={receipts}
        onReceiptClick={setActiveReceipt}
        activeReceipt={activeReceipt}
      />
      <ActiveReceipt receipt={activeReceipt} />
    </div>
  );

  const renderFileUploadSection = () => (
    <div className="no-receipt-found">
      <h6>No Receipts found</h6>
      <Row>
        <Col md="12" className="pl-3">
          <Form.Label className="txt-light-secondary">
            Upload Receipts <span className="txt-primary">*</span>
          </Form.Label>
          <Row className="receipts-box pl-3 col-md-12">
            <ListGroup horizontal className="upload-receipt-box flex-wrap gx-10">
              {files.map((file, index) => (
                <ListGroup.Item key={file.name} className="file-entry">
                  {file.name}
                  <Button
                    variant="link"
                    className="remove-icon"
                    onClick={() => removeFile(index)}
                    onKeyPress={(e) => handleKeyPress(e, () => removeFile(index))}
                  >
                    X
                  </Button>
                </ListGroup.Item>
              ))}
              <>
                <Button
                  variant="link"
                  className="plus-icon"
                  onClick={triggerFileUpload}
                >
                  +
                </Button>
                <input
                  type="file"
                  accept=".pdf, .heic, .jpg, .jpeg, .png"
                  multiple
                  onChange={onFilesChange}
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                />
              </>
            </ListGroup>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="border-bottom" />
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <button
            type="submit"
            className="btn btn-ftc-primary float-right"
            onClick={handleSubmit}
          >
            {isLoading ? (
              <Image src={Loader} height="35px" width="35px" />
            ) : (
              'Submit'
            )}
          </button>
        </Col>
      </Row>
    </div>
  );

  return receipts.length > 0 ? renderReceiptsSection() : renderFileUploadSection();
}

function ReceiptsList({ receipts, onReceiptClick, activeReceipt }) {
  console.log("Receipts-", receipts);
  return (
    <div className="receipts-list d-flex overflow-x-scroll custom-scrollbar pb-2">
      {receipts.map((receipt, index) => (
        <div
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          tabIndex={0}
          role="button"
          onClick={() => onReceiptClick(receipt)}
          className={`receipt-item d-flex justify-content-between flex-column ${activeReceipt === receipt ? 'active' : ''
            }`}
          onKeyDown={(e) => {
            if (e.keyCode === 13 || e.keyCode === 32) {
              onReceiptClick(receipt);
            }
          }}
        >
          {getFileExtension(receipt) === 'pdf' ? (
            // Display the first page of the selected PDF
            // <img src={receipt} alt={`Receipt ${index + 1}`} height="85px" />
            <iframe src={receipt} title="PDF Viewer" width="100px" height="85px" />
          ) : (
            <img src={receipt} alt={`Receipt ${index + 1}`} height="85px" />
          )}
          <div className="d-flex justify-content-center">
            Receipt {index + 1}
          </div>
        </div>
      ))}
    </div>
  );
}

function ActiveReceipt({ receipt }) {
  return (
    <div className="active-receipt">
      {getFileExtension(receipt) === 'pdf' ? (
        // Display all pages of the selected PDF
        <iframe src={receipt} title="PDF Viewer" width="100%" height="500px" />
      ) : (
        <img src={receipt} alt="Receipt" width="440px" />
      )}
    </div>
  );
}

export default ReceiptList;
